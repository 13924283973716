import React from "react";
import Footer from "../../components/Footer/Footer";
import SupportLeftNav from "../../components/TwoColumnLeftNav/SupportLeftNav";
import LocalizedLink from "../../components/localizedLink";

class SupportJaContent extends React.Component {
	render() {
		return (
			<div>
				<h2 className={"large bold"}>お取引や製品の販売に関するお問い合わせ</h2>

				            <p>----------------------------------------------------------</p>
                            <p>弊社は2024年12月28日（土）～2025年1月5日（日）を冬期休業とさせていただきます。</p>
                            <p>冬期休業期間中のお問い合わせにつきましては、1月6日（月）以降のご対応となりますのでご了承ください。</p>
                            <p>期間中はご迷惑ご不便をおかけいたしますが、何卒ご理解賜りますようお願い申し上げます。</p>
                            <p>----------------------------------------------------------</p>
                            <br /><br />


				<p>
					新規お取引、製品の販売に関しましては、「最寄の営業所」までお問い合わせください。
				</p>
				<p>　　　受付時間　平日：午前9時～午後5時まで</p>
				<p>　　　休業日　　土日、祝日、年末年始</p>


				<div className="contact-tel-list">
					<ul>
						<li className="tokyo-tel">
							<span className="content-name">東京本社</span>
							<a href="tel:03-3254-8111">
								<span className="content-tel">03-3254-8111</span>
							</a>
						</li>
						<li>
							<span className="content-name">東京営業所</span>
							<a href="tel:03-3254-8112">
								<span className="content-tel">03-3254-8112</span>
							</a>
						</li>
						<li className="osaka-tel">
							<span className="content-name">大阪営業所</span>
							<a href="tel:06-6944-0921">
								<span className="content-tel">06-6944-0921</span>
							</a>
						</li>
						<li>
							<span className="content-name">仙台営業所</span>
							<a href="tel:0224-83-4781">
								<span className="content-tel">0224-83-4781</span>
							</a>
						</li>
						<li>
							<span className="content-name">富山営業所</span>
							<a href="tel:076-494-3088">
								<span className="content-tel">076-494-3088</span>
							</a>
						</li>
						<li className="nagoya-tel">
							<span className="content-name">名古屋営業所</span>
							<a href="tel:052-204-1234">
								<span className="content-tel">052-204-1234</span>
							</a>
						</li>
						<li>
							<span className="content-name">福岡営業所</span>
							<a href="tel:092-451-1685">
								<span className="content-tel">092-451-1685</span>
							</a>
						</li>
					</ul>
				</div>

				<p>　　　.</p>
				<p>また、メールフォームからの各種お問い合わせはこちらです。</p>
				<div className="contact-mail">
					<a href={"/support/mail"} className="contact-mail-link" target={"_blank"} rel="noreferrer" >
						<span className="mail-icon-pc" />
						<span className="mail-icon-sp" />
						メールでのお問い合わせ
					</a>
				</div>

				<div className="content-container gray">				
						<p>
						お急ぎの方は、下記フリーダイヤルにお問い合わせをお願いいたします。
						</p>
						<div className="contact-tel-sp">
							<a href="tel:0120-389-513">
								<span className="content">0120-389-513</span>
							</a>
							<a href="tel:03-3254-8112">
								<span className="content">03-3254-8112</span>
							</a>
						</div>
						<div className="contact-tel-pc">
							<p>　　　株式会社佐藤計量器製作所　お問い合わせ窓口</p>
							<p>　　　受付時間　平日：午前9時～午後5時まで</p>
						    <p>　　　休業日　　土日、祝日、年末年始</p>
							</div>				
							<div className="contact-tel-box">
								<p>
								　　　電話
									<a href="tel:0120-389-513" className="content">
									　0120-389-513
									</a>
									　	(フリーダイヤル)
								</p>
								<p>
								　　　※かかりにくい場合、営業直通までご連絡ください。(営業直通
									<a href="tel:03-3254-8112" className="content">
										03-3254-8112
									</a>
									)
								</p>
							</div>

				</div>
			</div>
		);
	}
}

const Support = () => {
	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<article className="support-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					{pathName.indexOf("/en") === -1 ? (
						<SupportJaContent />
					) : typeof window !== "undefined" ? (
						(window.location.href = "/en/support/mail")
					) : (
						""
					)}
				</section>
			</article>
			<Footer />
		</>
	);
};
export default Support;